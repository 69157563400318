import { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import Button from '../components/UI/Button'
import { LocalStorageNames } from '../types'
import InputLabel from '../components/UI/InputLabel/'
import { URLs } from '../urls'
import { ROUTES } from '../router'

interface ILoginPageProps {
    setTitle: (param: string) => void
    navigateHandler: (props: string) => void
    setShowProgress: (param: boolean) => void
}

const loginValidationSchema = Yup.object().shape({
    email: Yup.string().email('Company email must be a valid email').required('Company email is required field'),
    password: Yup.string().required('Password is required'),
})

export const LoginPage = ({ setTitle, setShowProgress }: ILoginPageProps) => {
    const navigate = useNavigate()

    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
            // entity: IEntityType.warehouse,
        },
        onSubmit: async (values, { setSubmitting }) => {
            setSubmitting(true)
            // console.log(values)
            try {
                const response = await fetch(`${URLs.baseApi}/sso/auth`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(values),
                })

                // get token
                const data = await response.json()

                if (response.ok) {
                    localStorage.clear()
                    toast('Success', { type: 'success' })

                    if (!data.user.verified) {
                        localStorage.setItem(LocalStorageNames.token, data.token)
                        navigate(ROUTES.verification)
                        return
                    }
                    window.location.href = `${URLs.appHostName}/auth/${data!.token}`
                } else {
                    if (data?.email) {
                        toast(data?.email, { type: 'error' })
                    }
                    if (data?.password) {
                        toast(data?.password, { type: 'error' })
                    }
                }
            } catch (error: any) {
                console.log(error)
                toast(error?.message ?? 'An unexpected error occurred', { type: 'error' })
            }

            setSubmitting(false)
        },
        validationSchema: loginValidationSchema,
        validateOnChange: false,
    })

    useEffect(() => {
        setTitle('Sign In')
        setShowProgress(false)

        const emailParam = new URLSearchParams(window.location.search).get('email')
        if (emailParam) {
            formik.setFieldValue('email', emailParam)
        }
    }, [])

    const handleInputChange = (e: any) => {
        formik.handleChange(e)
        formik.setErrors({})
    }

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        formik.handleSubmit()
    }

    const slug = localStorage.getItem(LocalStorageNames.siteSlug)
    console.log(slug)

    return (
        <form onSubmit={handleSubmit}>
            {/* <div className="sign-up__row">
                <Select
                    id="account_type"
                    label="Account type"
                    style={{ textTransform: 'capitalize' }}
                    defaultValue={IEntityType.warehouse}
                    options={[
                        { label: IEntityType['Shipper/Broker'], value: IEntityType['Shipper/Broker'] },
                        { label: IEntityType.warehouse, value: IEntityType.warehouse },
                    ]}
                    onSelect={(elem) => formik.setFieldValue('entity', elem)}
                />
            </div> */}
            <div className="sign-up__row">
                <InputLabel
                    name="email"
                    className="sign-up__input"
                    value={formik.values.email}
                    errorMessage={formik.errors.email}
                    onChange={handleInputChange}
                    label="Email*"
                />
            </div>
            <div className="sign-up__row" style={{ marginBottom: 4 }}>
                <InputLabel
                    name="password"
                    className="sign-up__input"
                    value={formik.values.password}
                    errorMessage={formik.errors.password}
                    onChange={handleInputChange}
                    label="password*"
                    type="password"
                />
            </div>
            <div style={{ marginBottom: 20 }}>
                <Link className="link" to={'forgot-password'}>
                    Forgot your password?
                </Link>
            </div>
            <Button
                className="button sign-up__btn"
                type="submit"
                types={['blue', 'full']}
                disabled={!formik.isValid}
                loading={formik.isSubmitting}
            >
                Sign In
            </Button>

            <div>
                <span className="sign-up__link">Don&lsquo;t have an account?</span>
                <Link className="link" to={`/${slug ? '?slug=' + slug : ''}`}>
                    {' '}
                    Create an account
                </Link>
            </div>
        </form>
    )
}
